import React, { useEffect } from 'react';
import { useDrawer } from '../contexts/drawerContextProvider';

export const HomePage: React.FC = () => {
    const { setFullScreen } = useDrawer();

    useEffect(() => {
        setFullScreen(true);
    }, []);

    return null;
};
