import { useMediaQuery, useTheme } from '@material-ui/core';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollToTop = (): any => {
    const { pathname, hash } = useLocation();
    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.down('xs'));

    useEffect(() => {
        window.scrollTo(0, xs ? window.innerHeight : 0);
    }, [pathname, hash]);
    return null;
};
