import React from 'react';
import { Typography, useMediaQuery } from '@material-ui/core';
import { ChipGrid, Hyperlink, SectionHeader } from '../../components/titles';
import { ImageGrid } from '../../components/imageGrid';

export const SwingEssentials = (): JSX.Element => {
    const small = useMediaQuery('(max-width:400px)');
    const medium = useMediaQuery('(max-width:800px)');

    return (
        <>
            <SectionHeader
                title={'Swing Essentials'}
                subtitle={'On-demand golf lessons from PGA-certified instructors'}
            />

            <ChipGrid
                chipStyle={{ backgroundColor: '#4F4C81' }}
                chips={[
                    { label: '2 Dev', icon: 'people' },
                    { label: 'React', icon: 'code' },
                    { label: 'React Native', icon: 'code' },
                    { label: 'PHP', icon: 'code' },
                    { label: 'MySQL', icon: 'tool' },
                    { label: 'iOS / Android', icon: 'mobile' },
                    { label: 'Web', icon: 'web' },
                ]}
            />

            <img
                width={'100%'}
                height={'auto'}
                src={'./images/projects/swingessentials/web.png'}
                style={{ display: 'block', marginBottom: 24, border: '1px solid #e5e5e5' }}
            />

            <Typography variant={'h6'}>Overview</Typography>
            <Typography paragraph>
                Swing Essentials is a video-based golf lesson application that connects users to PGA-certified
                instructors without the hassle (or expense) of traveling to the golf course.
            </Typography>

            <ImageGrid
                gridImageProps={{
                    xs: small ? 6 : 4,
                    sm: medium ? 6 : 3,
                    md: 2,
                }}
                images={[
                    './images/projects/swingessentials/home.png',
                    './images/projects/swingessentials/submit.png',
                    './images/projects/swingessentials/lesson.png',
                    './images/projects/swingessentials/tips.png',
                    './images/projects/swingessentials/tip.png',
                    './images/projects/swingessentials/order.png',
                ]}
            />

            <Typography variant={'h6'}>Details</Typography>
            <Typography paragraph>
                This project started as a request to add a mobile app to supplement an existing web-based service but
                quickly morphed into a ground-up re-write of the entire platform.
            </Typography>
            <Typography paragraph>
                After showing initial design concepts to the client, we quickly got to work. A friend of mine, Theo
                Andrianos, was learning React Native and was looking for a project on which to test his skills and get
                some real-world experience. He tackled the first release of the mobile app while I focused on building a
                brand new database and REST API as well as the new React JS website.
            </Typography>
            <Typography paragraph>
                Discussions around the project began in late 2017 and the first release of the redesigned application
                suite launched in the spring of 2018. After that, I took over full development responsibilities for both
                the web and mobile applications. The platform has since undergone multiple incremental improvements,
                including another significant facelift.
            </Typography>

            <Typography variant={'h6'}>Learn More</Typography>
            <Typography paragraph>
                Check out the <Hyperlink href={'https://www.swingessentials.com'}>website</Hyperlink> or download the
                app on the{' '}
                <Hyperlink href="https://itunes.apple.com/us/app/swing-essentials-golf-app/id1382453145?mt=8">
                    App Store
                </Hyperlink>{' '}
                or{' '}
                <Hyperlink href="https://play.google.com/store/apps/details?id=com.swingessentials.app">
                    Google Play
                </Hyperlink>
                .
            </Typography>
        </>
    );
};
