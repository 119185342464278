import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { CssBaseline, ThemeProvider, createMuiTheme } from '@material-ui/core';
import { BrowserRouter } from 'react-router-dom';
import * as PXBThemes from '@pxblue/react-themes';
import { App } from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { ScrollToTop } from './router/scrollToTop';
import '@fontsource/open-sans';
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/700.css';
import '@fontsource/benchnine/400.css';
import '@fontsource/benchnine/300.css';
import '@fontsource/benchnine/700.css';
import './index.css';

const myTheme = Object.assign({}, PXBThemes.blue, {
    typography: {
        ...PXBThemes.blue.typography,
        h1: {
            // @ts-ignore
            ...(PXBThemes.blue.typography.h1 || {}),
            textTransform: 'uppercase',
        },
        h2: {
            // @ts-ignore
            ...(PXBThemes.blue.typography.h2 || {}),
            textTransform: 'uppercase',
        },
        h3: {
            // @ts-ignore
            ...(PXBThemes.blue.typography.h3 || {}),
            fontFamily: 'BenchNine, "Open Sans", sans-serif',
            textTransform: 'uppercase',
            fontWeight: 700,
            lineHeight: 1.2,
        },
        h4: {
            // @ts-ignore
            ...(PXBThemes.blue.typography.h4 || {}),
            fontFamily: 'BenchNine, "Open Sans", sans-serif',
            textTransform: 'uppercase',
            fontSize: '2rem',
            lineHeight: 1.2,
            fontWeight: 600,
        },
        h5: {
            // @ts-ignore
            ...(PXBThemes.blue.typography.h5 || {}),
            textTransform: 'uppercase',
            fontSize: '1.13rem',
            lineHeight: 1,
        },
        h6: {
            // @ts-ignore
            ...(PXBThemes.blue.typography.h6 || {}),
            fontFamily: 'BenchNine, "Open Sans", sans-serif',
            textTransform: 'uppercase',
        },
        subtitle1: {
            fontWeight: 400,
        },
        body1: {
            lineHeight: 1.8,
        },
    },
});

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={createMuiTheme(myTheme)}>
            <BrowserRouter>
                <ScrollToTop />
                <CssBaseline />
                <App />
            </BrowserRouter>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
