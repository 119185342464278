import React, { useEffect } from 'react';
import { Typography, useTheme, useMediaQuery } from '@material-ui/core';
import { useDrawer } from '../contexts/drawerContextProvider';
import { ChipGrid, PageToolbar, SectionHeader } from '../components/titles';

export const AboutMe = (): JSX.Element => {
    const theme = useTheme();
    const { setFullScreen } = useDrawer();
    const xs = useMediaQuery(theme.breakpoints.down('xs'));

    useEffect(() => {
        setFullScreen(false);
    }, []);

    return (
        <div style={{ minHeight: '100%', paddingLeft: xs ? 0 : 300 }}>
            <PageToolbar title={'About Me'} subtitle={'A quick introduction to who I am and what I do'} />

            <div style={{ maxWidth: 900, margin: '0 auto', padding: 16, paddingBottom: '50vh' }}>
                <SectionHeader
                    title={'Who Am I?'}
                    subtitle={'A User Experience Engineer with a passion for reusability and open source'}
                />

                <ChipGrid
                    chips={[
                        { label: 'Pittsburgh, PA', icon: 'location' },
                        { label: 'Developer', icon: 'code' },
                        { label: 'UX Designer', icon: 'design' },
                        { label: 'University of Virginia', icon: 'school' },
                        { label: 'Carnegie Mellon University', icon: 'school' },
                        { label: 'React', icon: 'code' },
                        { label: 'React Native', icon: 'code' },
                    ]}
                />

                <div
                    style={{
                        marginRight: 24,
                        marginBottom: 24,
                        float: 'left',
                        width: 200,
                        maxWidth: '50%',
                        borderRadius: 200,
                        overflow: 'hidden',
                    }}
                >
                    <img
                        width={'100%'}
                        height={'auto'}
                        src={'./images/bio/portrait.png'}
                        style={{ display: 'block' }}
                        alt={'Joseph Boyle head shot'}
                    />
                </div>

                <Typography variant={'h6'}>Quick Biography</Typography>
                <Typography paragraph>
                    {`I was born and raised in northern Virginia. I graduated from the University of Virginia with a BS in Computer Science, and then went on to complete my master's degree in Human-Computer Interaction at Carnegie Mellon University. I currently live in Pittsburgh, PA where I work for Eaton as the lead engineer on our PX Blue design system team.`}
                </Typography>
                <div style={{ clear: 'both' }} />
                <Typography variant={'h6'}>What I offer</Typography>
                <Typography paragraph>
                    {`As a User Experience Engineer, I bridge the gap between designers and developers. I pride myself on my ability to find clever solutions to complex design and programming problems. I’ve worked on software products spanning embedded, mobile, and desktop applications, consistently delivering exceptional results. I adapt quickly and am not afraid to jump into unfamiliar territory and learn new skills.`}
                </Typography>
                <Typography variant={'h6'}>Interests</Typography>
                <Typography paragraph>
                    {`I'm a big fan of React and React Native and over the years I've developed a strong appreciation for open source and sharing what I build with the greater development community. I am also a big proponent of automation and making things easy for developers so we can focus our efforts on more impactful work. Most of my spare time is spent learning French, weightlifting, gardening, hiking, and hammock camping.`}
                </Typography>
            </div>
        </div>
    );
};
