import { HomePage, AboutMe, Portfolio } from '../pages';

export const PAGES = [
    {
        title: 'Home Page',
        route: '/',
        component: HomePage,
    },
    {
        title: 'About Me',
        route: '/about',
        component: AboutMe,
    },
    {
        title: 'Recent Projects',
        route: '/portfolio',
        component: Portfolio,
    },
];
