import React from 'react';
import {
    createStyles,
    makeStyles,
    Theme,
    Typography,
    useTheme,
    Drawer,
    IconButton,
    useMediaQuery,
} from '@material-ui/core';
import { useHistory, useLocation } from 'react-router';
import { GitHub, LinkedIn, Mail } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { useDrawer } from '../contexts/drawerContextProvider';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        drawer: {
            transition: theme.transitions.create('all', {
                duration: theme.transitions.duration.leavingScreen,
                easing: theme.transitions.easing.easeInOut,
            }),
            zIndex: theme.zIndex.modal,
            top: 0,
            [theme.breakpoints.down('xs')]: {
                transition: 'none', // `calc(100vh - ${theme.spacing(7)}px)`,
                height: '100%',
            },
        },
        drawerPaper: {
            backgroundColor: theme.palette.primary.main,
            backgroundImage: `url('./images/background/circuit.svg')`,
            // backgroundSize: '140px',
            minHeight: '100vh',
            position: 'relative',
            borderRight: 'none',
            [theme.breakpoints.down('xs')]: {
                minHeight: 'unset', // `calc(100vh - ${theme.spacing(7)}px)`,
                height: '100%',
            },
        },
        drawerBody: {
            color: 'white',
            minHeight: `100vh`,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: `${theme.spacing(3)}px`,
            [theme.breakpoints.down('xs')]: {
                // minHeight: 'unset', //`calc(100vh - ${theme.spacing(7)}px)`,
                minHeight: '100%',
            },
        },
        buttonWrapper: {
            display: 'flex',
            alignItems: 'center',
            marginTop: 16,
        },
        iconButton: {
            color: 'white',
            backgroundColor: 'transparent',
            padding: 4,
        },
        icon: {
            fontSize: '2.5rem',
        },
        linksRow: {
            display: 'flex',
            alignItems: 'center',
            marginTop: 16,
        },
        link: {
            textDecoration: 'none',
            marginLeft: 8,
            marginRight: 8,
            color: 'inherit',
            '&:visited': {
                color: 'inherit',
            },
            '&$active': {
                position: 'relative',
            },
        },
        active: {
            '&:after': {
                content: '""',
                height: 2,
                width: '100%',
                backgroundColor: 'white',
                position: 'absolute',
                bottom: 0,
                left: 0,
            },
        },
    })
);

export const NavigationDrawer: React.FC = () => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const xs = useMediaQuery(theme.breakpoints.down('xs'));
    const history = useHistory();
    const location = useLocation();
    const { fullScreen } = useDrawer();

    return (
        <Drawer
            variant={'permanent'}
            className={classes.drawer}
            style={{
                position: fullScreen || xs ? 'static' : 'fixed',
                width: fullScreen || xs ? '100%' : 300,
                maxWidth: fullScreen || xs ? '100%' : '50%',
                // backgroundSize: fullScreen || xs ? 'cover' : '140px',
            }}
            anchor={xs ? 'top' : 'left'}
            classes={{ paper: classes.drawerPaper }}
            PaperProps={{ elevation: xs ? 0 : 16, style: { backgroundSize: fullScreen || xs ? 'cover' : '140%' } }}
        >
            <div className={classes.drawerBody}>
                <Typography variant={'h3'} onClick={(): void => history.push('/')} style={{ cursor: 'pointer' }}>
                    Joseph P. Boyle
                </Typography>
                <Typography variant={'h5'}>User Experience Engineer</Typography>
                <div className={classes.buttonWrapper}>
                    <IconButton
                        className={classes.iconButton}
                        onClick={(): void => {
                            window.location.href = 'mailto:boyle.p.joseph@gmail.com';
                        }}
                    >
                        <Mail color={'inherit'} className={classes.icon} />
                    </IconButton>
                    <IconButton
                        className={classes.iconButton}
                        onClick={(): void => {
                            window.open('https://github.com/joebochill', '_blank');
                        }}
                    >
                        <GitHub color={'inherit'} className={classes.icon} />
                    </IconButton>
                    <IconButton
                        className={classes.iconButton}
                        onClick={(): void => {
                            window.open('https://www.linkedin.com/in/joebochill', '_blank');
                        }}
                    >
                        <LinkedIn color={'inherit'} className={classes.icon} />
                    </IconButton>
                </div>
                <div className={classes.linksRow}>
                    <Typography variant={'h6'}>
                        <Link
                            to={'/about'}
                            className={clsx(classes.link, { [classes.active]: location.pathname.includes('about') })}
                        >
                            About Me
                        </Link>
                    </Typography>
                    <Typography variant={'h6'}>/</Typography>
                    <Typography variant={'h6'}>
                        <Link
                            to={'/portfolio'}
                            className={clsx(classes.link, {
                                [classes.active]: location.pathname.includes('portfolio'),
                            })}
                        >
                            Portfolio
                        </Link>
                    </Typography>
                    <Typography variant={'h6'}>/</Typography>
                    <Typography variant={'h6'}>
                        <a
                            href={'./resume/Boyle_Joseph_Resume.pdf'}
                            target={'_blank'}
                            rel={'noreferrer'}
                            className={classes.link}
                        >
                            Resume
                        </a>
                    </Typography>
                </div>
            </div>
        </Drawer>
    );
};
