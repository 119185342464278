import React from 'react';
import { Typography, useMediaQuery } from '@material-ui/core';
import { ChipGrid, Hyperlink, SectionHeader } from '../../components/titles';
import { ImageGrid } from '../../components/imageGrid';

export const PXBlue = (): JSX.Element => {
    const small = useMediaQuery('(max-width:400px)');
    const medium = useMediaQuery('(max-width:800px)');

    return (
        <>
            <SectionHeader title={'Power Xpert Blue'} subtitle={'Powering teams to build what matters'} />

            <ChipGrid
                chips={[
                    { label: '4 Dev / 1 Designer', icon: 'people' },
                    { label: 'React', icon: 'code' },
                    { label: 'React Native', icon: 'code' },
                    { label: 'Angular', icon: 'code' },
                    { label: 'Ionic', icon: 'code' },
                    { label: 'CircleCI', icon: 'tool' },
                    { label: 'Figma', icon: 'tool' },
                ]}
            />

            <img
                width={'100%'}
                height={'auto'}
                src={'./images/projects/pxblue/banner.jpg'}
                style={{ display: 'block', marginBottom: 24, border: '1px solid #e5e5e5' }}
            />

            <Typography variant={'h6'}>Overview</Typography>
            <Typography paragraph>
                PX Blue is a complete design system for software development at Eaton. Our mission is to accelerate the
                software development process while establishing a strong brand and consistency across multiple different
                product lines.
            </Typography>

            <ImageGrid
                gridImageProps={{
                    xs: small ? 12 : 6,
                    sm: medium ? 12 : 6,
                    md: 6,
                }}
                images={['./images/projects/pxblue/icons.png', './images/projects/pxblue/patterns.png']}
            />
            <ImageGrid
                gridImageProps={{
                    xs: small ? 6 : 3,
                    sm: medium ? 6 : 3,
                    md: 3,
                }}
                images={[
                    './images/projects/pxblue/showcase.png',
                    './images/projects/pxblue/drawer.png',
                    './images/projects/pxblue/workflow.png',
                    './images/projects/pxblue/workflowChinese.png',
                ]}
            />

            <Typography variant={'h6'}>Details</Typography>
            <Typography paragraph>
                {`PX Blue was first conceived in 2015. As a member of the User Experience team at Eaton, I was working on project that shared many design elements with a prior project but was being built in a different technology. Naturally, this gave us pause and we asked ourselves, "Why are we building these things all over again?"`}
            </Typography>
            <Typography paragraph>
                We recognized the need to create a library of reusable components and style guidelines to prevent
                ourselves and others from constantly recreating the wheel on every new project. At the time, Polymer was
                an emerging technology that promised the ability to build components once and use them in any framework.
                By 2017, we had amassed a library of 30+ components that were being used in multiple applications. This
                approach worked well for about a year, but quickly turned into a significant maintenance challenge given
                our small team size.
            </Typography>
            <Typography paragraph>
                We took this opportunity to conduct a full design thinking evaluation to understand the true needs of
                design / development teams within our organization. We spent four months conducting user research and
                evaluating existing design systems that we might be able to leverage as a new foundation to improve
                maintainability.
            </Typography>
            <Typography paragraph>
                The next phase of the design system (2.0) was based on Material Design, enabling teams to take advantage
                of existing design principles and open-source component. This also allowed our team to focus on the
                pieces of the system that are differentiators rather than spending all of our time on low-level items
                like button design.
            </Typography>
            <Typography paragraph>
                With this foundation in place, our team built additional materials for teams to use including color
                palettes, themes, custom iconography, design patterns, high-level components, reusable multi-screen
                workflows, code examples, a command line interface, and a comprehensive designer stickersheet for
                quickly prototyping projects in Figma. We currently maintain over 45 packages on NPM and are proud to be
                100% open source.
            </Typography>
            <Typography paragraph>
                {`In 2020, PX Blue received two Design & Innovation `}
                <Hyperlink
                    href={'https://www.designinnovationglobal.com/design-thinking/articles/di-global-awards-winners'}
                >
                    awards
                </Hyperlink>
                {` for Brand Transformation and Enterprise Design Transformation.`}
            </Typography>

            <Typography variant={'h6'}>Learn More</Typography>
            <Typography paragraph>
                Check out our <Hyperlink href={'https://www.pxblue.github.io'}>website</Hyperlink> to learn all about PX
                Blue or poke around our <Hyperlink href={'https://www.pxblue.github.io'}>GitHub organization</Hyperlink>{' '}
                to see what we have built.
            </Typography>
        </>
    );
};
