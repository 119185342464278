import React, { useState } from 'react';
import { DrawerContext } from './contexts/drawerContextProvider';
import { NavigationDrawer } from './router/drawer';
import { MainRouter } from './router/main';

export const App = (): JSX.Element => {
    const [fullScreen, setFullScreen] = useState(true);

    return (
        <DrawerContext.Provider
            value={{
                fullScreen,
                setFullScreen,
            }}
        >
            <NavigationDrawer />
            <MainRouter />
        </DrawerContext.Provider>
    );
};
