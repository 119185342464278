import React, { useEffect } from 'react';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { useDrawer } from '../contexts/drawerContextProvider';
import { PageToolbar, SectionDivider } from '../components/titles';
import { SwingEssentials } from './projects/swingEssentials';
import { PXBlue } from './projects/pxblue';
import { Foreseer } from './projects/foreseer';
import { PowerXpertInsight } from './projects/pxi';
import { Shoefitr } from './projects/shoefitr';

export const Portfolio = (): JSX.Element => {
    const theme = useTheme();
    const { setFullScreen } = useDrawer();
    const xs = useMediaQuery(theme.breakpoints.down('xs'));

    useEffect(() => {
        setFullScreen(false);
    }, []);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100%', paddingLeft: xs ? 0 : 300 }}>
            <PageToolbar title={'Recent Projects'} subtitle={'A selection of some of my most recent work'} />

            <div
                style={{
                    maxWidth: 900,
                    margin: '0 auto',
                    paddingTop: 48,
                    paddingLeft: 16,
                    paddingRight: 16,
                    paddingBottom: '50vh',
                }}
            >
                <SwingEssentials />
                <SectionDivider />
                <PXBlue />
                <SectionDivider />
                <Foreseer />
                <SectionDivider />
                <PowerXpertInsight />
                <SectionDivider />
                <Shoefitr />
            </div>
        </div>
    );
};
