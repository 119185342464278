import React from 'react';
import { Typography, useMediaQuery } from '@material-ui/core';
import { ChipGrid, Hyperlink, SectionHeader } from '../../components/titles';
import { ImageGrid } from '../../components/imageGrid';

export const Foreseer = (): JSX.Element => {
    const small = useMediaQuery('(max-width:400px)');
    const medium = useMediaQuery('(max-width:800px)');
    return (
        <>
            <SectionHeader title={'Foreseer'} subtitle={'A robust enterprise monitoring solution'} />

            <ChipGrid
                chipStyle={{ backgroundColor: '#333333' }}
                chips={[
                    { label: '8-10 Dev / 1-2 Designer', icon: 'people' },
                    { label: 'React', icon: 'code' },
                    { label: 'C++', icon: 'code' },
                    { label: 'Illustrator', icon: 'design' },
                    { label: 'Web', icon: 'web' },
                    { label: 'Highcharts', icon: 'chart' },
                ]}
            />

            <img
                width={'100%'}
                height={'auto'}
                src={'./images/projects/foreseer/banner.jpg'}
                style={{ display: 'block', marginBottom: 24, border: '1px solid #e5e5e5' }}
            />

            <Typography variant={'h6'}>Overview</Typography>
            <Typography paragraph>
                {`Foreseer is Eaton's flagship enterprise monitoring solution. It helps hospitals, universities, airports, factories, and other large-scale organizations monitor their network of connected devices (water, air, gas, electric, and steam). The primary focus of the V7 release was to modernize the outdated codebase by moving to React and make the application available across all modern web browsers.`}
            </Typography>

            <ImageGrid
                gridImageProps={{
                    xs: small ? 12 : 6,
                    sm: medium ? 12 : 6,
                    md: 6,
                }}
                gridContainerProps={{ alignItems: 'flex-start' }}
                images={[
                    './images/projects/foreseer/waveform.png',
                    './images/projects/foreseer/calendar.png',
                    './images/projects/foreseer/trendviewer.png',
                    './images/projects/foreseer/channel.png',
                    './images/projects/foreseer/phase.png',
                ]}
            />

            <Typography variant={'h6'}>Details</Typography>
            <Typography paragraph>
                Foreseer was a very well-established product line before I joined the team. I was initially brought onto
                this project as a UX designer where my primary responsibilities were to provide high-fidelity mock-ups
                with an updated look and feel. Because the project had such a long history, this proved to be very
                challenging — backwards compatibility had to be maintained in order to avoid costly upgrades for
                high-profile customers.
            </Typography>
            <Typography paragraph>
                Despite these challenges, I successfully introduced several visual enhancements to the application,
                including a toolbar for quick access to common functions and a sidebar for adding and editing new
                components, both of which received positive feedback from users.
            </Typography>
            <Typography paragraph>
                This project was also my first introduction to React development. Due to limited development resources
                on the team, I assisted with coding various pieces of the UI. I wrote the code for several key controls
                / gauges as well as the entire Waveform graphing utility using Highcharts.
            </Typography>

            <Typography variant={'h6'}>Learn More</Typography>
            <Typography paragraph>
                Learn more about Foreseer in the Eaton product{' '}
                <Hyperlink href={'https://www.eaton.com/us/en-us/catalog/services/foreseer.html'}>catalog</Hyperlink>.
                You can also see many images of the updated UI elements in the{' '}
                <Hyperlink
                    href={
                        'https://www.eaton.com/content/dam/eaton/services/eess/eess-documents/foreseer-7-3/eaton-foreseer-v7.3-webviews-editor-guide-manual-mn152090-en-us.pdf'
                    }
                >
                    Editor
                </Hyperlink>
                {` guide or the `}
                <Hyperlink
                    href={
                        'https://www.eaton.com/content/dam/eaton/services/eess/eess-documents/foreseer-7-2/eaton-foreseer-72210-webviews-guide-mn152052en.pdf'
                    }
                >
                    WebViews
                </Hyperlink>{' '}
                guide.
            </Typography>
        </>
    );
};
