import React from 'react';
import { Typography, useMediaQuery } from '@material-ui/core';
import { ChipGrid, Hyperlink, SectionHeader } from '../../components/titles';
import { ImageGrid } from '../../components/imageGrid';

export const Shoefitr = (): JSX.Element => {
    const small = useMediaQuery('(max-width:400px)');
    const medium = useMediaQuery('(max-width:800px)');

    return (
        <>
            <SectionHeader title={'Shoefitr'} subtitle={'A revolution in online shoe shopping'} />

            <ChipGrid
                chipStyle={{ backgroundColor: '#c11a1a' }}
                chips={[
                    { label: '4 Designers', icon: 'people' },
                    { label: 'Axure', icon: 'tool' },
                    { label: 'Web', icon: 'web' },
                ]}
            />

            <img
                width={'100%'}
                height={'auto'}
                src={'./images/projects/shoefitr/banner2.jpg'}
                style={{ display: 'block', marginBottom: 24 }}
            />

            <Typography variant={'h6'}>Overview</Typography>
            <Typography paragraph>
                Shoefitr was a startup company in Pittsburgh that sought to change the way people shop for shoes online.
            </Typography>

            <ImageGrid
                gridImageProps={{
                    xs: small ? 12 : 6,
                    sm: medium ? 12 : 6,
                    md: 6,
                }}
                gridContainerProps={{ alignItems: 'flex-start' }}
                images={['./images/projects/shoefitr/banner.jpg', './images/projects/shoefitr/download.jpeg']}
            />

            <Typography variant={'h6'}>Details</Typography>
            <Typography
                paragraph
            >{`For my MHCI Capstone Project at Carnegie Mellon, I worked on a team of four to help Shoefitr with their online shoe shopping tool. Using three-dimensional models of a vast number of shoe models, Shoefitr was able to provide detailed information about how a potential new shoe will fit on a customer based on their existing shoes. They wanted us to broadly consider what the future of their tool could look like and provide guidance on how to get there.`}</Typography>
            <Typography
                paragraph
            >{`Our team approached the project starting with a rather general goal — to improve the way individuals shop for shoes online. We spent a total of four months in the field researching how people shop for shoes, followed by six months of design and testing.`}</Typography>
            <Typography
                paragraph
            >{`We used a variety of techniques, ranging from semi-structured interviews to in-store contextual inquiries, where we shadowed participants as they shopped for shoes at a typical shoe retailer. After gathering this research, we synthesized it into four key findings.`}</Typography>
            <Typography
                paragraph
            >{`After completing the research phase, we then went through an iterative process of brainstorming, prototyping, testing, and redesign to develop a solution that would address our findings. In total, we created three paper prototypes, two mid-fidelity prototypes, and one high-fidelity prototype. We evaluated these prototypes with a total of 35 users, both in a lab setting and in the context of their homes. Due to legal agreements, we are not able to disclose any details about our final designs.`}</Typography>
            <Typography paragraph>
                In April of 2015, Shoefitr was{' '}
                <Hyperlink
                    href={
                        'https://techcrunch.com/2015/04/10/amazon-quietly-acquired-shoefitr-to-improve-how-it-sells-footwear-online/'
                    }
                >
                    acquired by Amazon
                </Hyperlink>
                . Their sizing tool appeared briefly on the amazon website, but was quietly taken down after a period of
                time due to reasons unknown.
            </Typography>

            <Typography variant={'h6'}>Learn More</Typography>
            <Typography paragraph>
                Shoefitr is no longer active, but you can still find information about their history online or check out
                their profile on{' '}
                <Hyperlink href={'https://www.crunchbase.com/organization/shoefitr'}>CrunchBase</Hyperlink>.
            </Typography>
        </>
    );
};
