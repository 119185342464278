import React from 'react';
import { Typography, useMediaQuery } from '@material-ui/core';
import { ChipGrid, Hyperlink, SectionHeader } from '../../components/titles';
import { ImageGrid } from '../../components/imageGrid';

export const PowerXpertInsight = (): JSX.Element => {
    const small = useMediaQuery('(max-width:400px)');
    const medium = useMediaQuery('(max-width:800px)');

    return (
        <>
            <SectionHeader
                title={'Power Xpert Insight'}
                subtitle={'Online power monitoring solution for facility management'}
            />

            <ChipGrid
                chips={[
                    { label: '10-15 Dev / 3-4 Designer', icon: 'people' },
                    { label: 'Silverlight / C# / XAML', icon: 'code' },
                    { label: 'Illustrator / Photoshop', icon: 'design' },
                    { label: 'Web', icon: 'web' },
                    { label: 'ComponentOne', icon: 'chart' },
                ]}
            />

            <img
                width={'100%'}
                height={'auto'}
                src={'./images/projects/pxi/pxi.png'}
                style={{ display: 'block', marginBottom: 24 }}
            />

            <Typography variant={'h6'}>Overview</Typography>
            <Typography paragraph>
                Power Xpert Insight was the first product at Eaton built using the full user experience design process.
                It was a multi-year project that started with comprehensive user research and ended up changing the way
                Eaton delivers software.
            </Typography>

            <ImageGrid
                gridImageProps={{
                    xs: small ? 12 : 6,
                    sm: medium ? 12 : 6,
                    md: 6,
                }}
                images={[
                    './images/projects/pxi/favorites.jpg',
                    './images/projects/pxi/capacity.jpg',
                    './images/projects/pxi/trendviewer.jpg',
                    './images/projects/pxi/oneline.jpg',
                ]}
            />

            <Typography variant={'h6'}>Details</Typography>
            <Typography paragraph>
                {`PXI was the first project I worked on upon joining Eaton. It was the first attempt at bringing
                user-centered design to Eaton's product line. PXI is an online power and energy monitoring solution that
                connects to electrical devices and displays assorted information, including real time values, historical
                trends, and alarms.`}
            </Typography>
            <Typography paragraph>
                {`Initially, I started work performing front-end styling of the interface using XAML. Eventually, I
                progressed and became the lead UX designer for the product. I was then responsible for performing user
                research and designing new features and functionalities.`}
            </Typography>
            <Typography paragraph>
                {`The redesign process for PXI took approximately two years from initial research to first release. We
                released two more major versions before the project was retired. Power Xpert Insight won the `}
                <Hyperlink
                    href={
                        'https://www.plantengineering.com/events-and-awards/product-of-the-year/2014/2014-product-of-the-year-winners.html'
                    }
                >
                    Plant Engineering Product of the Year
                </Hyperlink>{' '}
                Gold Award for Energy Management in 2013 and 2014.
            </Typography>
            <Typography paragraph>
                {`We didn't know it at the time, but PXI would go on to spawn a total paradigm shift at Eaton and give birth to a revolutionary way of doing product design and development through our PX Blue design system.`}
            </Typography>

            <Typography variant={'h6'}>Learn More</Typography>
            <Typography paragraph>
                Learn more about PXI in the Eaton product{' '}
                <Hyperlink
                    href={
                        'http://www.eaton.com/Eaton/ProductsServices/Electrical/ProductsandServices/PowerQualityandMonitoring/EndofLifeProducts/PowerXpertInsight/index.htm'
                    }
                >
                    catalog
                </Hyperlink>{' '}
                or by checking out the{' '}
                <Hyperlink
                    href={'https://www.eaton.com/ecm/groups/public/@pub/@electrical/documents/content/mn152001en.pdf'}
                >
                    user manual
                </Hyperlink>
                .
            </Typography>
        </>
    );
};
