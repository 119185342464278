import React, { HTMLAttributes, HTMLProps } from 'react';
import {
    AppBar,
    Chip,
    createStyles,
    Divider,
    makeStyles,
    Theme,
    Toolbar,
    Typography,
    ChipProps,
} from '@material-ui/core';
import clsx from 'clsx';
import { Code, Group, Info, Laptop, Room, School, Smartphone, Widgets, Palette, Equalizer } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        sectionHeader: {
            marginBottom: theme.spacing(3),
            [theme.breakpoints.down('xs')]: {
                textAlign: 'center',
            },
        },
        sectionTitle: {
            // fontWeight: 300,
            lineHeight: 1,
            marginBottom: theme.spacing(1),
        },
        sectionSubtitle: {
            lineHeight: 1,
        },
        divider: {
            margin: `${theme.spacing(8)}px ${-1 * theme.spacing(2)}px`,
        },
        chipRow: {
            display: 'flex',
            flexWrap: 'wrap',
            margin: `${theme.spacing(3)}px ${-1 * theme.spacing(0.5)}px`,
            [theme.breakpoints.down('xs')]: {
                justifyContent: 'center',
            },
        },
        chip: {
            margin: theme.spacing(0.5),
        },
        link: {
            textDecoration: 'none',
            color: theme.palette.primary.main,
            fontWeight: 600,
            '&:visited': {
                color: theme.palette.primary.main,
            },
        },
        toolbar: {
            padding: theme.spacing(2),
            display: 'block',
            [theme.breakpoints.down('xs')]: {
                textAlign: 'center',
            },
        },
        toolbarText: {
            lineHeight: 1,
        },
    })
);

type SectionHeaderProps = HTMLAttributes<HTMLDivElement> & {
    title: string;
    subtitle: string;
};
export const SectionHeader: React.FC<SectionHeaderProps> = (props) => {
    const { title, subtitle, className, ...other } = props;
    const classes = useStyles();

    return (
        <div className={clsx(classes.sectionHeader, className)} {...other}>
            <Typography variant={'h3'} className={classes.sectionTitle}>
                {title}
            </Typography>
            <Typography variant={'subtitle1'} className={classes.sectionSubtitle}>
                {subtitle}
            </Typography>
        </div>
    );
};

export const SectionDivider: React.FC = () => {
    const classes = useStyles();
    return <Divider className={classes.divider} />;
};

type IconType = 'people' | 'code' | 'tool' | 'mobile' | 'web' | 'design' | 'location' | 'dev' | 'school' | 'chart';
const getIconByType = (type: IconType): JSX.Element => {
    switch (type) {
        case 'people':
            return <Group />;
        case 'dev':
        case 'code':
            return <Code />;
        case 'tool':
            return <Widgets />;
        case 'mobile':
            return <Smartphone />;
        case 'web':
            return <Laptop />;
        case 'design':
            return <Palette />;
        case 'location':
            return <Room />;
        case 'school':
            return <School />;
        case 'chart':
            return <Equalizer />;
        default:
            return <Info />;
    }
};
type ChipGridProps = HTMLAttributes<HTMLDivElement> & {
    chips: Array<{
        label: string;
        icon: IconType;
    }>;
    chipStyle?: ChipProps['style'];
};
export const ChipGrid: React.FC<ChipGridProps> = (props) => {
    const { chips, chipStyle, ...other } = props;
    const classes = useStyles();
    return (
        <div className={classes.chipRow} {...other}>
            {chips.map((chip, ind) => (
                <Chip
                    key={`${chip.label}_${ind}`}
                    className={classes.chip}
                    icon={getIconByType(chip.icon)}
                    label={chip.label}
                    color="primary"
                    style={chipStyle}
                />
            ))}
        </div>
    );
};

type HyperlinkProps = HTMLProps<HTMLAnchorElement> & {
    internal?: boolean;
};
export const Hyperlink: React.FC<HyperlinkProps> = (props) => {
    const { internal = false, className, ...other } = props;
    const classes = useStyles();
    return (
        <a
            target={internal ? undefined : '_blank'}
            rel={internal ? undefined : 'noreferrer'}
            className={clsx(classes.link, className)}
            {...other}
        >
            {props.children}
        </a>
    );
};

type PageToolbarProps = {
    title: string;
    subtitle: string;
};
export const PageToolbar: React.FC<PageToolbarProps> = (props) => {
    const { title, subtitle } = props;
    const classes = useStyles();
    return (
        <AppBar position={'sticky'} color={'secondary'} elevation={1}>
            <Toolbar className={classes.toolbar}>
                <Typography variant={'h6'} className={classes.toolbarText}>
                    {title}
                </Typography>
                <Typography variant={'body2'} className={classes.toolbarText}>
                    {subtitle}
                </Typography>
            </Toolbar>
        </AppBar>
    );
};
